import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
const Home = lazy(() => import('./components/Home/index'));
const Layout = lazy(() => import('./components/Layout/index.js'));
const Contact = lazy(() => import('./components/Contact/index.jsx'));
const Skills = lazy(() => import('./components/Skills/index.jsx'));
const Projects = lazy(() => import('./components/Projects/index.jsx'));


function App() {

  useEffect(() => {
    document.title = "Portfolio"
  }, [])

  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path='/' element={<div className='flex flex-col-reverse sm:flex-row h-full lg:gap-36'> <Layout name={'home'}/><Home /> </div>} />
          <Route path='/contact' element={<div className='flex flex-col-reverse sm:flex-row h-full'> <Layout name={'contact'}/><Contact /> </div>} />
          <Route path='/skills' element={<div className='flex flex-col-reverse sm:flex-row h-full'> <Layout name={'skills'}/><Skills /> </div>} />
          <Route path='/projects' element={<div className='flex flex-col-reverse sm:flex-row h-full'> <Layout name={'projects'}/><Projects /> </div>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
